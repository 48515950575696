export const HandleAxiosError = (error) => {
	if (process.env.GATSBY_CONTENTSTACK_ENVIRONMENT !== 'production') {
		// eslint-disable-next-line no-console -- development only
		console.error('An error occurred:', error);
	}
	// add Sentry here?
	if (error?.response?.data) {
		return error.response.data;
	} else {
		return {
			success: false,
			error: { message: 'An unknown error occurred', error },
		};
	}
};
