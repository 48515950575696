import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useBranchData } from '../hooks/use-branch-data';
import { useShowroomUrls } from '../hooks/use-showroom-urls';
import { getSessionStorage, setSessionStorage } from '../components/utilities/functions';

export const BranchContext = createContext({});

export const useBranchContext = () => useContext(BranchContext);

export function BranchProvider(props) {
	const branches = useBranchData();
	const showroomUrls = useShowroomUrls();
	const [currentBranch, setCurrentBranch] = useState({});

	const updateCurrentBranch = (branch) => {
		if (!branch) return;
		if (getSessionStorage('CurrentBranch')?.formal_name === branch.formal_name) return;

		setCurrentBranch(branch);
		setSessionStorage('CurrentBranch', {
			formal_name: branch.formal_name,
			url: branch.branch_url,
		});
	};

	useEffect(() => {
		let params = new URLSearchParams(window.location.search);
		let branchID = params.get('branch');

		// Check for branch utm to exist within branch data
		if (branchID != null && branches?.find((branch) => branch.branch_retail?.concat(branch.branch_trade?.split(', '))?.includes(branchID))?.formal_name) {
			const current = branches?.find((branch) => branch.branch_retail?.concat(branch.branch_trade?.split(', '))?.includes(branchID));

			updateCurrentBranch(current);
			return;
		}

		// Persist current branch through general pages
		if (getSessionStorage('CurrentBranch')?.formal_name) {
			const current = branches?.find((branch) => branch.formal_name === getSessionStorage('CurrentBranch').formal_name);
			setCurrentBranch(current);
		}
	}, [branches, showroomUrls]);

	return (
		<BranchContext.Provider
			value={{
				currentBranch,
				setCurrentBranch,
				updateCurrentBranch,
			}}
		>
			{props.children}
		</BranchContext.Provider>
	);
}

BranchProvider.propTypes = {
	children: PropTypes.node,
};
