import axios from 'axios';
import { SetAuthHeader } from './utils/authHeader';
import { useOktaAuth } from './use-okta-auth';
import { HandleAxiosError } from './utils/axiosError';
import { clearErrors } from '../actions/utilities';
import { GET_ERRORS, GET_TILES } from '../actions/types';

let params = {};
const pellaAPIURL = process.env.GATSBY_PELLA_API;

export const useProduct = () => {
	const { authState, profile, oktaInitialized } = useOktaAuth();
	const accessToken = authState?.accessToken?.accessToken;

	const requestConfig = {
		headers: {
			...SetAuthHeader(accessToken),
		},
		withCredentials: true,
	};

	const loadItem = async (productID, clientID, drawingSize, filters = []) => {
		const userPostalCode = localStorage?.getItem('UserPostalCode');
		try {
			let data = {
				canvas: {
					width: drawingSize,
					height: drawingSize,
				},
			};
			filters?.length ? (data['filters'] = filters) : null;

			if (clientID) {
				params.client_id = clientID;
			}
			if (userPostalCode) {
				params.client_postal_code = userPostalCode;
			}

			const item = axios.post(`${pellaAPIURL}/web-product/api/v2/web/products/${productID}`, data, { ...requestConfig, params: params });
			return item;
		} catch (error) {
			HandleAxiosError(error);
		}
	};

	const getLineItemConfig = (quoteID, itemID, clientID, drawingSize, backendName) => {
		const userPostalCode = localStorage?.getItem('UserPostalCode');
		try {
			let data = {
				canvas: {
					width: drawingSize,
					height: drawingSize,
				},
				tile_backend_name: backendName,
			};

			if (clientID) {
				params.client_id = clientID;
			}
			if (userPostalCode) {
				params.client_postal_code = userPostalCode;
			}

			const item = axios.put(`${pellaAPIURL}/web-product/api/v2/web/quotes/${quoteID}/config/${itemID}`, data, { ...requestConfig, params: params });
			return item;
		} catch (error) {
			HandleAxiosError(error);
		}
	};

	const updateItem = (data, questionID, configKey, clientID) => {
		const userPostalCode = localStorage?.getItem('UserPostalCode');
		try {
			if (clientID) {
				params.client_id = clientID;
			}
			if (userPostalCode) {
				params.client_postal_code = userPostalCode;
			}

			const item = axios.put(`${pellaAPIURL}/web-product/api/v2/web/config/${configKey}/questions/${questionID}`, data, { ...requestConfig, params: params });
			return item;
		} catch (error) {
			HandleAxiosError(error);
		}
	};

	const reconfigure = (configKey, filters, drawingSize, clientID = 'H03') => {
		const userPostalCode = localStorage?.getItem('UserPostalCode');
		try {
			let data = {
				canvas: {
					width: drawingSize,
					height: drawingSize,
				},
			};
			filters.length ? (data['filters'] = filters) : null;

			if (clientID) {
				params.client_id = clientID;
			}
			if (userPostalCode) {
				params.client_postal_code = userPostalCode;
			}

			const item = axios.put(`${pellaAPIURL}/web-product/api/v2/web/config/${configKey}/reconfigure`, data, { ...requestConfig, params: params });
			return item;
		} catch (error) {
			HandleAxiosError(error);
		}
	};

	const updateConfigQuantity = (quantity, configKey) => {
		const userPostalCode = localStorage?.getItem('UserPostalCode');
		try {
			const data = {
				quantity: quantity,
			};

			if (userPostalCode) {
				params.client_postal_code = userPostalCode;
			}

			const item = axios.put(`${pellaAPIURL}/web-product/api/v2/web/config/${configKey}/quantity`, data, { ...requestConfig, params: params });
			return item;
		} catch (error) {
			HandleAxiosError(error);
		}
	};

	const fetchProductTiles = () => async (dispatch) => {
		const userPostalCode = localStorage?.getItem('UserPostalCode');
		const apiUrl = `${pellaAPIURL}/web-product/api/v2/web/products/product-tiles`;
		dispatch(clearErrors());

		try {
			if (userPostalCode) {
				params.client_postal_code = userPostalCode;
			}

			if (oktaInitialized) {
				params.client_id = profile.isPro ? 'H04' : 'H03';
			}

			const tiles = await axios.get(apiUrl, { ...requestConfig, params: params });
			dispatch({
				type: GET_TILES,
				payload: tiles.data.tiles,
			});
		} catch (error) {
			dispatch({
				type: GET_ERRORS,
				payload: error,
			});
		}
	};

	const fetchProductTile = (productID) => {
		const userPostalCode = localStorage?.getItem('UserPostalCode');
		const apiUrl = `${pellaAPIURL}/web-product/api/v2/web/products/product-tiles/${productID}`;

		try {
			if (userPostalCode) {
				params.client_postal_code = userPostalCode;
			}

			if (oktaInitialized) {
				params.client_id = profile.isPro ? 'H04' : 'H03';
			}

			const tile = axios.get(apiUrl, { ...requestConfig, params: params });
			return tile;
		} catch (error) {
			HandleAxiosError(error);
		}
	};

	const fetchProductList = (data, clientID = 'H03') => {
		const userPostalCode = localStorage?.getItem('UserPostalCode');
		try {
			if (clientID) {
				params.client_id = clientID;
			}
			if (userPostalCode) {
				params.client_postal_code = userPostalCode;
			}

			const products = axios.post(`${pellaAPIURL}/web-product/api/v2/web/products`, data, { ...requestConfig, params: params });
			return products;
		} catch (error) {
			HandleAxiosError(error);
		}
	};

	const updateDrawing = (configKey, viewID, drawingSize) => {
		const userPostalCode = localStorage?.getItem('UserPostalCode');
		try {
			let data = {
				id: viewID,
				canvas: {
					width: drawingSize,
					height: drawingSize,
				},
			};

			if (userPostalCode) {
				params.client_postal_code = userPostalCode;
			}

			const item = axios.put(`${pellaAPIURL}/web-product/api/v2/web/config/${configKey}/drawing/`, data, { ...requestConfig, params: params });
			return item;
		} catch (error) {
			HandleAxiosError(error);
		}
	};

	const getCurrentConfigurationState = (configKey) => {
		const userPostalCode = localStorage?.getItem('UserPostalCode');
		try {
			if (userPostalCode) {
				params.client_postal_code = userPostalCode;
			}

			const getConfig = axios.get(`${pellaAPIURL}/web-product/api/v2/web/config/${configKey}`, { ...requestConfig, params: params });
			return getConfig;
		} catch (error) {
			HandleAxiosError(error);
		}
	};

	const getBundle = (bundleID, clientID = 'H03') => {
		const userPostalCode = localStorage?.getItem('UserPostalCode');
		try {
			if (clientID) {
				params.client_id = clientID;
			}
			if (userPostalCode) {
				params.client_postal_code = userPostalCode;
			}

			const bundle = axios.get(`${pellaAPIURL}/web-product/api/v2/web/bundles/${bundleID}`, { ...requestConfig, params: params });
			return bundle;
		} catch (error) {
			HandleAxiosError(error);
		}
	};

	return {
		loadItem,
		getLineItemConfig,
		updateItem,
		reconfigure,
		updateConfigQuantity,
		fetchProductTiles,
		fetchProductTile,
		fetchProductList,
		updateDrawing,
		getCurrentConfigurationState,
		getBundle,
	};
};
