import { caZipRegex, emailRegex, nameRegex, phoneRegex, usZipRegex } from './constants';

/**
 * Validation functions.
 * @module Validators
 */

/**
 * Validates a postal code.
 * @param {string} [value=''] - The value to be validated.
 * @returns {boolean} - True if the value exists and is a valid postal code, otherwise false.
 */
export const isValidPostal = (value = '') => {
	if (typeof value !== 'string') {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isValidPostal - Invalid Postal Code type: value must be a string');
	}
	return value && typeof value === 'string' && (usZipRegex.test(value) || caZipRegex.test(value)) ? true : false;
};

/**
 * Validates an email address.
 * @param {string} [value=''] - The value to be validated.
 * @returns {boolean} - True if the value exists and is a valid email address, otherwise false.
 */
export const isValidEmail = (value = '') => {
	if (typeof value !== 'string') {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isValidEmail - Invalid Email type: value must be a string');
	}
	return value && typeof value === 'string' && emailRegex.test(value) ? true : false;
};

/**
 * Validates a name.
 * @param {string} [value=''] - The value to be validated.
 * @returns {boolean} - True if the value exists and is a valid name, otherwise false.
 */
export const isValidName = (value = '') => {
	if (typeof value !== 'string') {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isValidName - Invalid Name type: value must be a string');
	}
	return value && typeof value === 'string' && nameRegex.test(value) ? true : false;
};

/**
 * Validates a phone number.
 * @param {string} [value=''] - The value to be validated.
 * @returns {boolean} - True if the value exists and is a valid phone number, otherwise false.
 */
export const isValidPhoneNumber = (value = '') => {
	if (typeof value !== 'string') {
		// eslint-disable-next-line no-console -- console.error here is acceptable
		console.error('isValidPhoneNumber - Invalid Phone type: value must be a string');
	}
	return value && typeof value === 'string' && phoneRegex.test(value) ? true : false;
};

/**
 * Validates if a given value is an array and has at least one element.
 * @param {array} value - The value to be validated.
 * @returns {boolean} - True if the value exists and is a non-empty array, otherwise false.
 */
export const isValidArray = (value) => (value && Array.isArray(value) && value.length > 0 ? true : false);

/**
 * Validates whether a given value exists and is an object.
 * @param {object} value - The value to be validated.
 * @returns {boolean} - True if the value exists and is a non-null object, otherwise false.
 */
export const isValidObject = (value) => (value && value != null && typeof value === 'object' ? true : false);
